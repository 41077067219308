/* roboto-regular - latin_cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/Roboto/roboto-v20-latin_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/fonts/Roboto/roboto-v20-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/Roboto/roboto-v20-latin_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/Roboto/roboto-v20-latin_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
  url('/fonts/Roboto/roboto-v20-latin_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/Roboto/roboto-v20-latin_cyrillic-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin_cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/Roboto/roboto-v20-latin_cyrillic-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/fonts/Roboto/roboto-v20-latin_cyrillic-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/Roboto/roboto-v20-latin_cyrillic-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/Roboto/roboto-v20-latin_cyrillic-500.woff') format('woff'), /* Modern Browsers */
  url('/fonts/Roboto/roboto-v20-latin_cyrillic-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/Roboto/roboto-v20-latin_cyrillic-500.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* raleway-regular - latin_cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/Raleway/raleway-v18-latin_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/fonts/Raleway/raleway-v18-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/Raleway/raleway-v18-latin_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/Raleway/raleway-v18-latin_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
  url('/fonts/Raleway/raleway-v18-latin_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/Raleway/raleway-v18-latin_cyrillic-regular.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-600 - latin_cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/Raleway/raleway-v18-latin_cyrillic-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/fonts/Raleway/raleway-v18-latin_cyrillic-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/Raleway/raleway-v18-latin_cyrillic-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/Raleway/raleway-v18-latin_cyrillic-600.woff') format('woff'), /* Modern Browsers */
  url('/fonts/Raleway/raleway-v18-latin_cyrillic-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/Raleway/raleway-v18-latin_cyrillic-600.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-800 - latin_cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  src: url('/fonts/Raleway/raleway-v18-latin_cyrillic-800.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/fonts/Raleway/raleway-v18-latin_cyrillic-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/Raleway/raleway-v18-latin_cyrillic-800.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/Raleway/raleway-v18-latin_cyrillic-800.woff') format('woff'), /* Modern Browsers */
  url('/fonts/Raleway/raleway-v18-latin_cyrillic-800.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/Raleway/raleway-v18-latin_cyrillic-800.svg#Raleway') format('svg'); /* Legacy iOS */
}
