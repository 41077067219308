@import 'fonts.css';

html,
body,
#root {
  margin: 0;
  background-color: #ffffff;
  height: 100%;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  width: 100%;
  padding: 0 !important;
  scroll-behavior: smooth;
}

* {
  -webkit-locale: auto;
}

.smartbanner--custom-design .smartbanner__icon {
  width: 44px;
  height: 44px;
  background-size: cover;
  top: 50%;
  transform: translateY(-50%);
  left: 45px;
}

.smartbanner--custom-design .smartbanner__exit {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translateY(-50%);
  width: 22px;
  height: 22px;
  left: 20px;
}

.grecaptcha-badge { visibility: hidden; }

